<template>
  <div class="po-common-info">
    <b-row>
      <b-col cols="6">
        <b-form-group>
          <template>
            <span class="font-weight-bold">Người tạo:</span>
          </template>
          <p class="mt-2">{{ mainModel.createdBy }}</p>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group>
          <template>
            <span class="font-weight-bold">Người liên hệ:</span>
          </template>
          <p class="mt-2">{{ mainModel.inChargeEmployeeName }}</p>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6">
        <b-form-group>
          <template>
            <span class="font-weight-bold">Ngày tạo:</span>
          </template>
          <p class="mt-2">{{ mainModel.createdAt }}</p>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group>
          <template>
            <span class="font-weight-bold">Tổng tiền:</span>
          </template>
          <p class="mt-2">
            {{ formatMoney(mainModel.totalAmount) }}
          </p>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6" v-if="!parentPO">
        <b-form-group>
          <template>
            <span class="font-weight-bold">Kho:</span>
          </template>
          <p class="mt-2">{{ mainModel.storeName }}</p>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group>
          <template>
            <span class="font-weight-bold">Nhà cung cấp:</span>
          </template>
          <p class="mt-2">
            {{ mainModel.providerName }}
          </p>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group>
          <template>
            <span class="font-weight-bold">Lý do nhập hàng:</span>
          </template>
          <p class="mt-2">{{ mainModel.importReason }}</p>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group>
          <template>
            <span class="font-weight-bold">Ghi chú:</span>
          </template>
          <p class="mt-2">{{ mainModel.description }}</p>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: ['mainModel', 'parentPO'],
  data() {
    return {};
  },
  methods: {
    formatMoney(number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
  },
};
</script>
