<template>
  <div>
    <b-modal
      ref="cancel-po-modal"
      hide-footer
      :title="title"
      id="modal-prevent-closing"
    >
      <b-form-group class="required-control col-md-6 pl-0">
        <label>Lý do hủy:</label>
        <b-form-select
          class="select-style"
          v-model="selectedCancelReason"
          :options="listCancelReason"
          size="sm"
          value-field="id"
          text-field="name"
        >
        </b-form-select>
      </b-form-group>
      <b-form-group class="required-control">
        <label>Mô tả lý do hủy:</label>
        <b-form-textarea
          size="sm"
          v-model="$v.form.cancelReason.$model"
          :placeholder="'Thêm mô tả lý do hủy...'"
          :rows="6"
          :max-rows="6"
          :state="validateState('cancelReason')"
        ></b-form-textarea>
        <b-form-invalid-feedback id="input-live-feedback"
          >Vui lòng nhập lý do hủy</b-form-invalid-feedback
        >
      </b-form-group>
      <b-button
        style="fontweight: 600; width: 70px"
        variant="primary"
        size="sm"
        @click="onSubmit"
        >Lưu</b-button
      >
      <b-button
        style="margin-left: 10px; font-weight: 600; width: 70px"
        variant="secondary"
        size="sm"
        @click="hideModal"
        >Hủy</b-button
      >
    </b-modal>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import ApiService from '@/core/services/api.service';
import { makeToastSuccess, makeToastFaile } from './../../../utils/common';

export default {
  props: ['purchaseOrderCode', 'purchaseOrderId', 'mode'],
  mixins: [validationMixin],
  data() {
    return {
      title: '',
      form: {
        cancelReason: '',
      },
      selectedCancelReason: 1,
      listCancelReason: [
        {
          id: 1,
          name: 'Thông tin',
        },
        {
          id: 2,
          name: 'Tồn kho',
        },
        {
          id: 3,
          name: 'Sức bán',
        },
        {
          id: 4,
          name: 'Ngân sách',
        },
      ],
    };
  },
  validations: {
    form: {
      cancelReason: { required },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    showModal: function () {
      this.title = `Trả phiếu yêu cầu nhập hàng ${this.purchaseOrderCode}`;
      this.$refs['cancel-po-modal'].show();
    },
    hideModal: function () {
      this.$refs['cancel-po-modal'].hide();
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const data = {
        id: this.purchaseOrderId,
        cancelReasonDescription: this.$v.form.cancelReason.$model,
        cancelReasonId: this.selectedCancelReason,
        cancelReasonType: this.mode,
      };

      ApiService.put('purchase-order/cancel', data).then(({ data }) => {
        if (data.status === 1) {
          makeToastSuccess(data.message);
          this.hideModal();
          this.$emit('onSuccess', true);
        } else {
          makeToastFaile(data.message);
        }
      });
    },
  },
};
</script>