<template>
  <div class="po-payment">
    <b-row>
      <b-col>
        <b-form-group :disabled="!editable">
          <label>Tiền mặt</label>
          <b-form-input
            class="input-style text-right"
            size="sm"
            type="text"
            v-model="mainModel.cashAmount"
            placeholder="Nhập số tiền mặt"
            v-mask="currency"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group :label="'Tài khoản tiền mặt'" :disabled="!editable">
          <Autosuggest
            class="border-radius-none"
            :model="
              mainModel.cashAccountName
                ? mainModel.cashAccountName
                : 'Nhập tài khoản tiền mặt'
            "
            :suggestions="filteredOptionsCash"
            placeholder="tài khoản tiền mặt"
            :limit="10"
            :disabled="!editable"
            @select="onSelectedCash"
            @change="onInputChangeCash"
          >
            <template #custom="{ suggestion }">
              <div>
                <span>{{ suggestion.item.suggestionName }}</span>
              </div>
            </template>
          </Autosuggest>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group :disabled="!editable">
          <label>Tiền chuyển khoản</label>
          <b-form-input
            class="input-style text-right"
            size="sm"
            type="text"
            v-model="mainModel.transferAmount"
            placeholder="Nhập số tiền"
            v-mask="currency"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group :label="'Tài khoản chuyển khoản'" :disabled="!editable">
          <Autosuggest
            class="border-radius-none"
            :model="
              mainModel.transferAccountName
                ? mainModel.transferAccountName
                : 'Nhập tài khoản chuyển khoản'
            "
            :suggestions="filteredOptionsTransfer"
            placeholder="tài khoản chuyển khoản"
            :limit="10"
            :disabled="!editable"
            @select="onSelectedTransfer"
            @change="onInputChangeTransfer"
          >
            <template #custom="{ suggestion }">
              <div>
                <span>{{ suggestion.item.suggestionName }}</span>
              </div>
            </template>
          </Autosuggest>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group :label="'Ngày thanh toán'" :disabled="!editable">
          <date-picker
            placeholder="Chọn ngày thanh toán"
            class="mb-2 form-control form-control-sm"
            :config="dpConfigs.date"
            v-model="mainModel.payDate"
          ></date-picker>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { currencyMask } from './../../../utils/common';
import timeUtils from '@/utils/date';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
// Import datePicker component
import datePicker from 'vue-bootstrap-datetimepicker';
import { map } from 'lodash';
import ApiService from '@/core/services/api.service';
import { cmdUrl } from '@/utils/apiUrl';

export default {
  props: ['editable', 'mainModel'],
  data() {
    return {
      currency: currencyMask,
      dpConfigs: timeUtils.DP_CONFIG,
      filteredOptionsCash: [],
      optionsCash: [],
      filteredOptionsTransfer: [],
      optionsTransfer: [],
    };
  },
  components: {
    datePicker,
    Autosuggest,
  },
  created() {
    if (!this.mainModel.id) {
      this.fetchAccountants('cash');
    }
    this.fetchAccountants('transfer');
  },
  methods: {
    fetchAccountants: async function (mode) {
      this.optionsCash = [];
      let params = {
        type: mode === 'cash' ? '1' : '2',
      };
      let url = cmdUrl.AccountantUrl.byType;
      if (this.mainModel.storeId !== null && mode === 'cash') {
        url = 'accountants/getByStore';
        params = {
          type: mode === 'cash' ? '1' : '2',
          storeId: this.mainModel.storeId,
        };
      }
      ApiService.query(url, {
        params,
      }).then((response) => {
        const data = response.data.data || [];
        map(data, (item) => {
          const element = {
            code: item.code,
            name: item.name,
            suggestionName: `(${item.code}) - ${item.name}`,
          };
          if (mode === 'cash') {
            this.optionsCash.push(element);
          } else if (mode === 'transfer') {
            this.optionsTransfer.push(element);
          }
        });
        this.filteredOptionsCash = [...this.optionsCash];
        this.filteredOptionsTransfer = [...this.optionsTransfer];
      });
    },
    onSelectedCash(option) {
      this.searchCash = option.item.name;
      this.mainModel.cashAccountCode = option.item.code;
    },
    onInputChangeCash(text) {
      this.searchCash = text;
      const filteredData = this.optionsCash
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.code.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, 10);
      this.filteredOptionsCash = [...filteredData];
    },
    onSelectedTransfer(option) {
      this.searchTransfer = option.item.name;
      this.mainModel.transferAccountCode = option.item.code;
    },
    onInputChangeTransfer(text) {
      this.searchTransfer = text;
      const filteredData = this.optionsTransfer
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.code.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, 10);
      this.filteredOptionsTransfer = [...filteredData];
    },
  },
};
</script>
